.body {
  background-image: linear-gradient(
      269.6deg,
      rgba(0, 0, 0, 0.56) 36.36%,
      rgba(20, 20, 20, 0.4424) 62.05%,
      rgba(153, 153, 153, 0) 141.98%
    ),
    url('/public/images/bg-login.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.mainWrapper {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgotPasswordCard {
  min-width: 400px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 170px;
}

.title {
  font-size: 21px;
  font-weight: 500;
  margin: 30px 10px 7px 25px;
}

.line {
  width: 181px;
  height: 3px;
  background-color: #f39058;
  margin-left: 25px;
  border-radius: 20px;
}

.emailLabel {
  margin: 30px 0 10px 25px;
  font-size: 15px;
}

.inputEmail {
  margin: 0 22px 10px 22px;
}

.inputEmail > input {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #a8a8a8;
  padding: 0 10px;
}

.inputEmail > input:focus {
  outline: none;
  border-color: #f39058;
}

.btnWrapper {
  margin: 35px 17px 35px 22px;
}

.btn {
  background-color: #000;
  border: none;
  color: #fff;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
}

.btn:hover {
  opacity: 0.7;
}

.validationMessage {
  color: #ff0000;
  margin: 22px;
  font-weight: 500;
}

.successBoxWrapper {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.successBox {
  background-color: rgb(255, 255, 255);
}

.successBox > h5 {
  font-weight: 700;
}

.successBox > span {
  font-size: 14px;
}

/* loading css */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 45px;
  height: 34px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  margin: 7px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* end of loading css */
@media only screen and (max-width: 767px) {
  .forgotPasswordCard {
    min-width: 100%;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 170px;
  }

  .mainWrapper {
    width: 100%;
    padding: 0 10px;
  }

  .title {
    margin: 30px 10px 10px 17px;
  }

  .line {
    margin-left: 19px;
  }

  .emailLabel {
    margin: 30px 0 10px 15px;
  }

  .btnWrapper {
    margin: 35px 13px 35px 15px;
  }

  .inputEmail {
    margin: 0 15px 10px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .mainWrapper {
    width: 100%;
  }
}
