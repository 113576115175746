.container > h3 {
  display: flex;
  justify-content: center;
  padding-bottom: 35px;
  font-weight: 600;
  font-size: 20px;
}

.sectionSearch {
  display: flex;
  gap: 0.5em;
}

.sectionSearch__profession,
.sectionSearch__location {
  width: 50%;
}

.sectionSearch__btn {
  background-color: #f39058;
  border: none;
  color: #fff;
  width: 180px;
  height: 48px;
  border-radius: 10px;
  text-decoration: none;
  transition: all 0.2s ease 0s;
}

.sectionSearch__btn:hover {
  background-color: #f89962;
  opacity: 0.8;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}

.titlePopular {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

.popularContent {
  display: flex;
  justify-content: center;
  gap: 1em;
  flex-wrap: wrap;
}

.popularContent > div {
  background-color: #989898;
  width: 160px;
  height: 55px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  color: #fff;
  font-weight: 600;
}

.downArrow {
  display: flex;
  justify-content: center;
  margin-top: 170px;
  margin-bottom: 20px;
}

.secondContainer {
  background-color: #323232;
  width: 100%;
}

.secondContainerWrap {
  max-width: 1200px;
  padding: 0 25px;
  margin: auto;
  display: flex;
  gap: 1.5em;
}

.imgAbout2 {
  width: 200px;
  padding: 20px 0;
  object-fit: cover;
}

.imgAbout1 {
  width: 200px;
  margin-bottom: -100px;
  padding-top: 138px;
}

.aboutDescription {
  padding: 100px 25px;
}

.aboutDescriptionContent {
  color: #fff;
  font-size: 20px;
}

.thirdContainer {
  max-width: 1200px;
  padding: 0 25px;
  margin: auto;
  padding-bottom: 100px;
}

.expertTitle {
  margin: 240px 0 50px 0;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 35px;
}

.btnJoinUs {
  background-color: #f39058;
  border: none;
  color: #fff;
  width: 153px;
  height: 40px;
  border-radius: 10px;
  text-decoration: none;
  margin-top: 20px;
  transition: all 0.2s ease 0s;
}

.btnJoinUs:hover {
  opacity: 0.8;
}

.card {
  width: 300px;
}

.expertImage {
  width: 300px;
}

.expertTitleCard {
  font-size: 25px;
  font-weight: 600;
  padding: 10px 0;
}

.expertDescriptionCard {
  font-size: 18px;
}

.secondAbout {
  display: flex;
  gap: 8em;
  justify-content: center;
  align-items: center;
  padding: 190px 25px 80px 25px;
}

.thirdAbout {
  display: flex;
  gap: 8em;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
}

.secondAboutImg {
  width: 400px;
}

.secondAboutDescription {
  font-size: 18px;
}

.testimonialTitle {
  font-size: 35px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.testimonialCard {
  width: 400px;
  border-radius: 10px;
  background-color: #ececec;
  padding: 30px;
}

.testimonialCardWrapper {
  display: flex;
  gap: 2em;
  width: 2000px;
}

.testimonialCardOverflow {
  overflow-x: scroll;
  padding: 20px 10px 20px 20px;
}

.testimonialCardTitle {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .container {
    width: 100%;
  }

  .container > h3 {
    font-size: 17px;
  }

  .secondContainerWrap {
    width: 100%;
    margin: auto;
    display: flex;
    padding: 0;
    gap: 1.5em;
  }

  .thirdContainer {
    width: 100%;
    padding: 0;
  }

  .secondAbout {
    flex-direction: column;
    gap: 3em;
  }

  .thirdAbout {
    flex-direction: column-reverse;
    gap: 3em;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 50px;
  }

  .secondContainerWrap > img {
    display: none;
  }

  .secondAboutImg {
    width: 100%;
  }

  .expertImage {
    width: 100%;
  }

  .sectionSearch {
    flex-direction: column;
    padding: 0 15px;
  }

  .sectionSearch__profession,
  .sectionSearch__location {
    width: 100%;
  }

  .sectionSearch > button {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    width: 100%;
    padding: 160px 10px 0 10px;
  }

  .secondContainerWrap {
    width: 100%;
    margin: auto;
    display: flex;
    gap: 1.5em;
  }

  .secondContainerWrap > img {
    display: none;
  }

  .secondAboutTitle {
    font-size: 42px;
  }

  .thirdContainer {
    width: 100%;
  }

  .secondAboutImg {
    width: 50%;
  }

  .secondAbout {
    gap: 4em;
  }

  .thirdAbout {
    gap: 4em;
  }
}
