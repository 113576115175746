.body {
  background-image: linear-gradient(
      269.6deg,
      rgba(0, 0, 0, 0.56) 36.36%,
      rgba(20, 20, 20, 0.4424) 62.05%,
      rgba(153, 153, 153, 0) 141.98%
    ),
    url('/public/images/bg-login.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  height: 120vh;
}

.mainLoginWrapper {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4em;
  /* background-color: black; */
}

.loginCard {
  width: 400px;
  background-color: #fff;
  margin-top: 110px;
  border-radius: 10px;
}

.loginTagline > :nth-child(1) {
  color: #fff;
  font-size: 45px;
  font-weight: 700;
}

.loginTagline > :nth-child(2) {
  color: #fff;
}

.title {
  font-size: 21px;
  font-weight: 500;
  margin: 30px 6px 10px 25px;
}

.line {
  width: 76px;
  height: 3px;
  background-color: #f39058;
  margin-left: 25px;
  margin-bottom: 18px;
  border-radius: 20px;
}

.validationMessage {
  color: #ff0000;
  margin: 22px;
  font-weight: 500;
}

.textDanger {
  color: #ff0000;
}

.label {
  font-size: 15px;
}

.label {
  margin: 0 25px 10px 22px;
}

.input {
  margin: 0 22px 10px 22px;
}

.input > input {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #a8a8a8;
  padding: 0 10px;
}

.input > input:focus {
  outline: none;
  border-color: #f39058;
}

.forgotPassword {
  color: #f3803e;
  font-size: 13px;
  margin-left: 25px;
  font-weight: 500;
}

.btnLogin {
  background-color: #000;
  border: none;
  color: #fff;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
}

.btnLogin:hover {
  opacity: 0.7;
}

.btnLoginWrapper {
  margin: 30px 20px 15px;
}

.signUp {
  font-size: 13px;
  margin-left: 25px;
  margin-bottom: 30px;
}

.signUpLink {
  font-weight: 500;
  color: #f3803e;
}

.googleLoginSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.googleLoginSection > div {
  margin-bottom: 10px;
  font-weight: 600;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 34px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  margin: 7px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 767px) {
  .body {
    height: 150vh;
  }

  .mainLoginWrapper {
    width: 100%;
    gap: 0;
    padding: 0 15px;
  }

  .loginTagline {
    display: none;
  }

  .loginCard {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .body {
    height: 110vh;
  }

  .mainLoginWrapper {
    width: 100%;
    gap: 0;
    padding: 0 15px;
  }

  .loginTagline {
    display: none;
  }

  .loginCard {
    width: 100%;
  }
}
