.socialMedia > a {
  color: #fe5d40;
}

.activeLink {
  color: #f39058 !important;
}

.notActiveLink {
  color: #494949;
}

.firstContainer {
  width: 100%;
  background-color: #323232;
  position: fixed;
}

.firstContainerWrap {
  padding: 100px 0 20px 0;
  width: 85%;
  margin: auto;
}

.sectionSearch {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.sectionSearch > input[type='text'] {
  border-radius: 10px;
  width: 100%;
  height: 35px;
  padding: 0 22px;
  border: 1px solid #a8a8a8;
}

.sectionSearch > input[type='text']:focus {
  outline: none;
  border-color: #f39058;
}

.sectionSearch > button {
  background-color: #f39058;
  border: none;
  color: #fff;
  width: 250px;
  height: 40px;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
}

.secondContainer {
  width: 100%;
  margin: auto;
  padding: 180px 0 20px 20px;
}

.companiesWrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  gap: 35px;
  padding: 40px;
}

.card {
  width: calc(33% * 100vw);
  background-color: #fffefe;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 8px;
  display: flex;
}

.card:hover {
  color: #f39058;
}

.scrollTop {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

.scrollTop > button {
  background-color: #f39058;
  border: none;
  color: #fff;
  width: 150px;
  height: 47px;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  transition: all 0.2s ease 0s;
}

.scrollTop > button:hover {
  background-color: #fda06a;
  box-shadow: 2px 2px 1px rgba(232, 232, 232, 0.45);
}

.image > img {
  width: 89px;
  object-fit: cover;
}

.image {
  padding: 20px 10px;
}

.content {
  padding: 12px 12px 12px 5px;
  min-height: 166px;
}

.company {
  font-weight: 500;
  font-size: 12px;
  width: 235px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Title {
  font-size: 14px;
  font-weight: 700;
  width: 235px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.detail {
  font-size: 13px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.lastUpdate {
  display: flex;
  justify-content: flex-end;
  font-size: 11px;
  margin-top: 20px;
  font-weight: 600;
}

.detailContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* detail companies */
.container {
  width: 100%;
  background-color: #ffff;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.25);
}

.containerDetail {
  width: 100%;
  margin: auto;
  gap: 2em;
  padding: 100px 45px 0 45px;
}

.banner {
  width: 100%;
  height: 200px;
  background-color: #4f4f4f;
  border-radius: 20px;
  overflow: hidden;
}

.banner > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.companyMainInfo > img {
  width: 183px;
  object-fit: cover;
}

.companyInfo {
  display: flex;
  gap: 3em;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  min-height: 213px;
}

.companyMainInfo {
  display: flex;
  gap: 1em;
  align-items: center;
}

.companyName {
  font-size: 2em;
  font-weight: 600;
}

.companyCategory {
  font-size: 1.1em;
  font-weight: 500;
}

.contactSection {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 7px;
  font-size: 16px;
}

.contactIcon {
  color: #f39058;
}

.navCompanyWrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 20px 0;
}

.navCompany {
  display: flex;
  gap: 2em;
}

.socialMedia {
  display: flex;
  gap: 1em;
  color: #494949;
  margin-top: 15px;
  font-size: 20px;
}

.companyDetailWrapper {
  width: 100%;
  margin: auto;
  padding: 0 45px 80px 45px;
}

.aboutTitle {
  font-size: 18px;
  font-weight: 600;
  margin: 45px 0 45px 0;
}

.map {
  width: 460px;
  height: 300px;
}

.mapWrapper {
  border-radius: 10px;
  width: 460px;
  height: 300px;
  overflow: hidden;
  margin-top: 30px;
}

/* end of detail companies */

/* gallery companies */
.gallery {
  display: grid;
  gap: 1em;
  grid-template-columns: auto auto auto;
}

.gallery > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

/* end of gallery companies */

/* job companies  */
.jobWrapper {
  display: flex;
  /* grid-template-columns: auto auto auto; */
  flex-wrap: wrap;
  gap: 1.5em;
}

/* end of job companies */

@media only screen and (max-width: 329px) {
  .firstContainer {
    padding: 10px;
    position: static;
  }

  .firstContainerWrap {
    width: 100%;
  }

  .sectionSearch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }

  .secondContainer {
    width: 100%;
    margin: auto;
    padding: 14px;
  }

  .companiesWrapper {
    display: grid;
    grid-template-columns: auto;
    gap: 2em;
    padding: 25px 15px;
  }

  .card {
    width: 100%;
    background-color: #fffefe;
    box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 25%);
    border-radius: 8px;
    display: block;
    padding: 10px;
  }

  .image {
    padding: 0;
  }

  .Title {
    width: 100%;
  }

  .company {
    width: 200px;
  }

  /* detail companies */
  .containerDetail {
    width: 100%;
    padding-top: 60px;
  }

  .banner {
    border-radius: 0;
  }

  .companyInfo {
    align-items: flex-start;
    flex-direction: column;
    padding: 0 15px;
    gap: 1em;
  }

  .companyMainInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .companyMainInfo > img {
    width: 120px;
    margin-top: 10px;
    background-color: #fff;
  }

  .contactSection {
    justify-content: flex-start;
  }

  .navCompanyWrapper {
    flex-direction: column-reverse;
    align-items: start;
    padding: 0 15px;
    gap: 1.5em;
  }

  .navCompanyWrapper > .socialMedia {
    font-size: 20px;
  }

  .companyDetailWrapper {
    width: 100%;
    margin: auto;
    padding: 0 15px;
  }

  .mapWrapper {
    border-radius: 15px;
    width: 100%;
    overflow: hidden;
    margin-top: 30px;
  }

  .map {
    width: 100%;
    height: 300px;
  }

  /* end of detail companies */

  /* gallery companies */
  .gallery {
    grid-template-columns: auto;
  }

  /* end of gallery companies */

  /* job companies  */
  .jobWrapper {
    display: grid;
    grid-template-columns: auto;
    gap: 1.5em;
  }

  /* end of job companies */
}

@media only screen and (min-width: 330px) and (max-width: 767px) {
  .firstContainer {
    padding: 10px;
    position: static;
  }

  .firstContainerWrap {
    width: 100%;
  }

  .sectionSearch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }

  .secondContainer {
    width: 100%;
    margin: auto;
    padding: 14px;
  }

  .companiesWrapper {
    display: grid;
    grid-template-columns: auto;
    gap: 2em;
    padding: 25px 17px;
  }

  .card {
    width: 100%;
  }

  .Title {
    width: 100%;
  }

  .company {
    width: 12rem;
  }

  /* detail companies */
  .containerDetail {
    width: 100%;
    padding: 105px 25px 0 25px;
  }

  .banner {
    border-radius: 0;
  }

  .companyInfo {
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 15px;
    gap: 1em;
  }

  .companyMainInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .companyMainInfo > img {
    width: 120px;
  }

  .contactSection {
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .navCompanyWrapper {
    flex-direction: column-reverse;
    align-items: start;
    gap: 1.5em;
  }

  .navCompanyWrapper > .socialMedia {
    font-size: 20px;
  }

  .companyDetailWrapper {
    width: 100%;
    margin: auto;
    padding: 0 25px 50px 25px;
  }

  .mapWrapper {
    border-radius: 15px;
    width: 100%;
    overflow: hidden;
    margin-top: 30px;
  }

  .map {
    width: 100%;
    height: 300px;
  }

  /* end of detail companies */

  /* gallery companies */
  .gallery {
    grid-template-columns: auto;
  }

  /* end of gallery companies */

  /* job companies  */
  .jobWrapper {
    display: grid;
    grid-template-columns: auto;
    gap: 1.5em;
  }

  /* end of job companies */
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .firstContainer {
    padding: 10px;
    position: static;
  }

  .firstContainerWrap {
    width: 100%;
  }

  .secondContainer {
    width: 100%;
    margin: auto;
    padding: 14px;
  }

  .companiesWrapper {
    display: grid;
    grid-template-columns: auto auto;
    gap: 2em;
    padding: 25px 22px;
  }

  .card {
    width: 100%;
  }

  .Title {
    width: 100%;
  }

  .company {
    width: 200px;
  }

  /* detail companies */
  .containerDetail {
    width: 100%;
    padding: 105px 25px 0 25px;
  }

  .banner {
    border-radius: 0;
  }

  .companyInfo {
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 15px;
    gap: 1em;
  }

  .companyMainInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .companyMainInfo > img {
    width: 120px;
  }

  .contactSection {
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .navCompanyWrapper {
    align-items: start;
    gap: 1.5em;
  }

  .navCompanyWrapper > .socialMedia {
    font-size: 20px;
  }

  .companyDetailWrapper {
    width: 100%;
    margin: auto;
    padding: 0 25px 50px 20px;
  }

  .mapWrapper {
    border-radius: 15px;
    width: 450px;
    overflow: hidden;
    margin-top: 30px;
  }

  .map {
    width: 100%;
    height: 300px;
  }

  /* end of detail companies */

  /* gallery companies */
  .gallery {
    grid-template-columns: auto auto;
  }

  /* end of gallery companies */

  /* job companies  */
  .jobWrapper {
    display: grid;
    grid-template-columns: 50% auto;
    gap: 1.9em;
  }

  /* end of job companies */
}

/* @media (min-width: 1025px) and (max-width: 1280px) {
  .companiesWrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0px, 1fr));
    gap: 35px;
    padding: 40px;
  }
} */
