.firstContainer {
  width: 100%;
  background-color: #323232;
}

.firstContainerWrap {
  padding: 20px 40px 20px 50px;
  margin: auto;
}
.sectionSearch {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}
.sectionSearch__profession,
.sectionSearch__location {
  width: 35%;
}
.apply {
  width: 200px;
  height: 35px;
  background-color: #f39058;
  color: #fff;
  border-radius: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.apply:hover {
  background-color: #f46512;
}

@media only screen and (max-width: 329px) {
  .firstContainerWrap {
    padding: 9px 6px 6px 6px;
    margin: auto;
  }
  .sectionSearch {
    flex-direction: column;
    padding: 6px;
  }
  .sectionSearch__profession,
  .sectionSearch__location {
    width: 100%;
  }
}

@media only screen and (min-width: 330px) and (max-width: 767px) {
  .firstContainerWrap {
    padding: 20px 5px;
    margin: auto;
  }
  .sectionSearch {
    flex-direction: column;
    padding: 10px;
  }
  .sectionSearch__profession,
  .sectionSearch__location {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .firstContainer {
    width: 100%;
  }
  .firstContainerWrap {
    width: 100%;
    padding: 10px;
  }
  .sectionSearch {
    padding: 10px;
  }
}
