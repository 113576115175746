.body {
  background-image: linear-gradient(
      269.6deg,
      rgba(0, 0, 0, 0.56) 36.36%,
      rgba(20, 20, 20, 0.4424) 62.05%,
      rgba(153, 153, 153, 0) 141.98%
    ),
    url('/public/images/bg-login.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  height: 60vw;
}

.mainRegisterWrapper {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4em;
}

.registerCard {
  width: 400px;
  background-color: #fff;
  margin-top: 110px;
  border-radius: 10px;
}

.registerTagline > :nth-child(1) {
  color: #fff;
  font-size: 45px;
  font-weight: 700;
}

.registerTagline > :nth-child(2) {
  color: #fff;
}

.titleSignUp {
  font-size: 21px;
  font-weight: 500;
  margin: 30px 6px 10px 23px;
}

.line {
  width: 86px;
  height: 3px;
  background-color: #f39058;
  margin-left: 23px;
  margin-bottom: 25px;
  border-radius: 20px;
}

.emailLabel {
  margin: 0px 0 10px 21px;
  font-size: 15px;
}

.inputEmail,
.inputPassword,
.inputConfirmationPassword,
.inputFullname {
  margin: 0 25px 10px 22px;
}

.inputEmail > input,
.inputPassword > input,
.inputConfirmationPassword > input,
.inputFullname > input {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #a8a8a8;
  padding: 0 10px;
}

.inputEmail > input:focus,
.inputPassword > input:focus,
.inputConfirmationPassword > input:focus,
.inputFullname > input:focus {
  outline: none;
  border-color: #f39058;
}

.passwordLabel,
.confirmationPasswordLabel {
  margin: 0 0 10px 21px;
  font-size: 15px;
}

.label {
  margin: 13px 0 10px 21px;
  font-size: 15px;
}

.btnWrapper {
  margin: 30px 18px 15px;
}

.agreement {
  margin-left: 25px;
  font-size: 13px;
  color: #a6a6a6;
}

.btnSignUp {
  background-color: #000;
  border: none;
  color: #fff;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
}

.btnSignUp:hover {
  opacity: 0.7;
}

.login {
  font-size: 13px;
  margin-left: 25px;
  margin-bottom: 30px;
}

.loginLink {
  font-weight: 500;
  color: #f3803e;
}

.textDanger {
  color: crimson;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 34px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  margin: 7px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 767px) {
  .body {
    height: 100vh;
  }

  .mainRegisterWrapper {
    width: 100%;
    gap: 0;
    padding: 0 15px;
  }

  .registerTagline {
    display: none;
  }

  .registerCard {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .body {
    height: 70vh;
  }

  .mainRegisterWrapper {
    width: 100%;
    gap: 0;
    padding: 0 15px;
  }

  .registerTagline {
    display: none;
  }

  .registerCard {
    width: 100%;
  }
}
