@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer base {
  ul,
  ol {
    list-style: revert;
    list-style-position: inside;
  }
}

* {
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #f7f7f7;
  /* height: 100vh; */
}

/* custom toggle */
.custom-toggle.react-toggle--checked .react-toggle-track {
  background-color: #ff9e4b;
}
.custom-toggle.react-toggle--checked:hover:not(.react-toggle--disabled)
  .react-toggle-track {
  background-color: #ff9e4b;
}
.custom-toggle.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #ffffff;
}
.custom-toggle.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 1px 2px #bebebe;
  -moz-box-shadow: 0px 0px 1px 2px #bebebe;
  box-shadow: 0px 0px 1px 3px #bebebe;
}

.custom-toggle.react-toggle:active:not(.react-toggle--disabled)
  .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 2px 5px #bebebe;
  -moz-box-shadow: 0px 0px 2px 5px #bebebe;
  box-shadow: 0px 0px 2px 5px #bebebe;
}

/* end of custom toggle */

/* a:hover {
  color: black !important;
} */

/* editor style */
.DraftEditor-editorContainer {
  z-index: 0 !important;
}



