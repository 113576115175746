.container {
  position: fixed;
  background-color: #fff;
  z-index: 111;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.25);
  width: 100%;
}

.navbarMain {
  height: 75px;
  width: 100%;
  padding: 0 30px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.logoNav {
  width: 170px;
  padding-top: 4px;
}

.leftSectionNav {
  display: flex;
  align-items: center;
  gap: 2em;
}

.rightSectionNav {
  display: flex;
}

.web {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2em;
  font-size: 18px;
  color: #494949;
}

.userSection {
  display: flex;
  gap: 15px;
  justify-content: end;
  align-items: center;
  border: none;
  background-color: #fff;
  cursor: pointer;
  color: #494949;
  font-size: 17px;
  font-weight: 600;
}

.user {
  font-size: 15px;
  text-transform: capitalize;
}

.menuResWrap__content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.menu div {
  font-size: 13px;
  padding: 8px;
  cursor: pointer;
  white-space: nowrap;
}

.menu > div:hover {
  background-color: #e6e4e4;
  color: #000;
}

.menu {
  display: none;
  position: absolute;
  margin-top: 8px;
  right: 0;
  min-width: 150px;
  background-color: #fff;
  border: 1px solid #dbd9d9;
  border-radius: 3px;
}

.menu__content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.show {
  display: block;
}

.notAuth {
  display: flex;
  align-items: center;
  gap: 2em;
  font-size: 14px;
}

.link {
  display: flex;
  gap: 2em;
}

.linkNav {
  color: #494949;
}

.companyBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  cursor: pointer;
  color: #fff;
  background-color: #f39058;
  width: 250px;
  height: 32px;
  border-radius: 30px;
  transition: all 0.2s ease 0s;
}

.companyBtn:hover {
  opacity: 0.8;
}

.mobile {
  display: none;
}

.menuResponsive {
  display: none;
}

a:hover {
  color: #f39058 !important;
}

.activeLink {
  color: #f39058 !important;
}

.notActiveLink {
  color: #1f1f37;
}

@media only screen and (max-width: 767px) {
  .container {
    width: 100%;
  }

  .navbarMain {
    width: 100%;
  }

  .leftSectionNav > .link {
    display: none;
  }

  .rightSectionNav .web {
    display: none;
  }

  .mobile {
    display: flex;
    gap: 0.8em;
    align-items: center;
    font-size: 30px;
    color: #494949;
  }

  .menu {
    width: 100px;
    height: 100px;
    background-color: #f39058;
  }

  .navbarMain {
    padding: 0 15px;
  }

  .menuResponsive {
    display: none;
    position: absolute;
    top: 0;
    background-color: #fff;
    width: 100%;
    height: 100vh;
    font-size: 20px;
    font-weight: 600;
  }

  .open {
    display: block;
  }

  .menuResWrap {
    padding: 0 22px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    color: #1f1f37;
  }

  .userResponsive {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .authResponsive {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .close {
    font-size: 29px;
    display: flex;
    justify-content: end;
    padding: 25px 15px;
  }

  .user {
    font-size: 20px;
    text-transform: capitalize;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    width: 100%;
    padding: 0 10px;
  }

  .navbarMain {
    width: 100%;
  }

  .leftSectionNav > .link {
    display: none;
  }

  .rightSectionNav .web {
    display: none;
  }

  .mobile {
    display: flex;
    gap: 0.9em;
    align-items: center;
    font-size: 30px;
  }

  .menu {
    width: 100px;
    height: 100px;
    background-color: #f39058;
  }

  .navbarMain {
    padding: 0 15px;
  }

  .menuResponsive {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 100vh;
    font-size: 20px;
    font-weight: 600;
  }

  .open {
    display: block;
  }

  .menuResWrap {
    padding: 0 22px;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .user {
    font-size: 20px;
    text-transform: capitalize;
  }

  .userResponsive {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .authResponsive {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .menuResWrap__link > a {
    color: #4f4f4f !important;
  }

  .close {
    font-size: 29px;
    display: flex;
    justify-content: end;
    padding: 25px;
  }
}
