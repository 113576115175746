.wrapperEditor {
  border: 1px solid black;
  background-color: white;
}

.editorEditor {
  padding: 0px 10px;
  min-height: 222px;
}

.toolbarEditor {
  background-color: black;
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.2);
}
