.jobWrap {
  display: flex;
  flex-flow: column nowrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  align-items: stretch;
  padding: 20px 9px;
}
.jobGrid {
  display: grid;
  gap: 24px 32px;
  grid-template-columns: 1fr 1fr;
}

.socialMediaCompany > a {
  color: #323232 !important;
}

.socialMediaCompany > a:hover {
  color: #f39058 !important;
}

.jobGrid > a:hover {
  color: black !important;
}

.firstContainer {
  width: 100%;
  background-color: #323232;
}

.firstContainerWrap {
  padding: 20px 40px 20px 50px;
  margin: auto;
}

.sectionSearch {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.sectionSearch__profession,
.sectionSearch__location {
  width: 35%;
}

.sectionSearch > button {
  background-color: #f39058;
  border: none;
  color: #fff;
  width: 180px;
  height: 47px;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  transition: all 0.2s ease 0s;
}

.sectionSearch > button:hover {
  background-color: #fda06a;
  box-shadow: 2px 2px 1px rgba(232, 232, 232, 0.45);
}

.secondContainer {
  width: 100%;
  margin: auto;
  display: flex;
  gap: 2em;
  padding: 0px 110px;
}

.resetBtn {
  display: flex;
  align-items: center;
  gap: 0.5em;
  border: none;
  color: black;
  margin: 10px 0;
  background-color: #fff;
  font-weight: 600;
}

.filter {
  position: sticky;
  position: -webkit-sticky;
  top: 70px;
  width: 360px;
  height: calc(100vh - 70px);
  padding: 20px 10px 20px 0;
}

.filterWrap {
  width: 350px;
  height: 90vh;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow-y: scroll;
}

.filterContent {
  width: 350px;
  height: 100vh;
  padding: 0 15px;
}

.job {
  width: 900px;
  padding: 25px 5px;
  display: flex;
  justify-content: start;
  gap: 40px;
  flex-wrap: wrap;
}

.infiniteScroll {
  padding: 25px 5px;
  display: flex;
  justify-content: start;
  gap: 40px;
  flex-wrap: wrap;
}

.card {
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 15%);
}

.image > img {
  width: 89px;
}

.image {
  padding: 20px 10px;
}

.content {
  padding: 12px 12px 12px 5px;
  color: #4f4f4f;
}

.company {
  font-weight: 500;
  font-size: 12px;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.jobTitle {
  font-size: 14px;
  font-weight: 700;
  width: 257px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.detail {
  display: flex;
  gap: 1em;
  font-size: 13px;
  margin-top: 10px;
}

.salary {
  margin-top: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.lastUpdate {
  display: flex;
  justify-content: flex-end;
  font-size: 11px;
  margin-top: 20px;
  font-weight: 600;
}

.show {
  font-size: 17px;
  font-weight: 700;
  padding: 15px;
}

.buttonShowWrap {
  display: flex;
  gap: 12px;
  padding: 15px 15px;
}

.buttonShow.active {
  background-color: black;
  border: none;
  color: #fff;
  width: 250px;
  height: 34px;
  border-radius: 50px;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
}

.buttonShow {
  background-color: #fff;
  border: none;
  color: #000;
  width: 250px;
  height: 34px;
  border-radius: 50px;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid #b7b7b7;
}

.workType {
  padding: 15px 15px;
  margin-top: 20px;
  font-size: 17px;
  font-weight: 700;
}

/* radio item */

.radioItem {
  /* display: inline-block; */
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radioItem input[type='radio'] {
  display: none;
}

.radioItem label {
  color: #666;
  font-weight: normal;
  cursor: pointer;
}

.radioItem label:before {
  content: ' ';
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #f39058;
  background-color: transparent;
}

.radioItem input[type='radio']:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: ' ';
  display: block;
  background: #f39058;
}

/* end of radio item */
.radioContent {
  padding: 0 15px 20px 15px;
}

.toggleContent {
  padding: 15px 15px 30px 15px;
}

.filterResponsiveBtn {
  display: none;
}

.filterRes {
  display: none;
}

/* detail job */
.containerDetailJob {
  width: 100%;
  margin: auto;
  display: flex;
  gap: 2em;
  padding-top: 100px;
}

.aboutCompany {
  width: 400px;
  display: flex;
  align-items: flex-start;
}

.aboutCompanyWrap {
  width: 400px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.detailJobContent {
  width: 800px;
}

.aboutTitle {
  font-size: 23px;
  font-weight: 600;
}

.lineAbout {
  width: 100px;
  height: 3px;
  border-radius: 5px;
  background-color: #f39058;
}

.companyWrap {
  display: flex;
  align-items: center;
  gap: 1em;
  margin: 25px 0;
}

.logoCompany {
  width: 100px;
}

.companyName {
  font-size: 18px;
  font-weight: 600;
}

.companySubName {
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: #989898;
}

.socialMediaCompany {
  display: flex;
  gap: 14px;
  margin: 15px 0;
  font-size: 19px;
}

.companyContact {
  display: flex;
  font-size: 13px;
  font-weight: 500;
  align-items: center;
  margin-bottom: 10px;
  gap: 8px;
}

.contactIcon {
  font-size: 18px;
}

.whoWeAre {
  font-size: 16px;
  font-weight: 600;
  margin: 25px 0;
}

.companyDescription {
  font-size: 15px;
}

.status {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
}

.subStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 25px;
  background-color: #323232;
  color: #fff;
  border-radius: 30px;
  font-size: 12px;
}

.jobContent {
  display: flex;
  gap: 25px;
  margin: 20px 0;
}

.logoCompanyJobContent {
  width: 200px;
}

.jobContentTitle {
  font-size: 30px;
  font-weight: 600;
}

.jobContentSubTitle {
  color: #f39058;
  font-weight: 600;
}

.jobCategory {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  margin: 15px 0;
  font-size: 15px;
}

.jobCategoryIcon {
  font-size: 20px;
}

.apply {
  width: 200px;
  height: 35px;
  background-color: #f39058;
  color: #fff;
  border-radius: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.apply:hover {
  background-color: #f46512;
}

.shareJob {
  width: 30px;
  height: 30px;
  background-color: #f39058;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 20px 0;
  cursor: pointer;
}

.requirementTitle {
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0;
}

.internCategory {
  display: flex;
  justify-content: start;
  gap: 1em;
  flex-wrap: wrap;
}

.internCategory > div {
  background-color: #989898;
  width: auto;
  /* height: 55px; */
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  color: #fff;
  font-weight: 600;
}

.scrollTop {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

.scrollTop > button {
  background-color: #f39058;
  border: none;
  color: #fff;
  width: 150px;
  height: 47px;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  transition: all 0.2s ease 0s;
}

.scrollTop > button:hover {
  background-color: #fda06a;
  box-shadow: 2px 2px 1px rgba(232, 232, 232, 0.45);
}

.modalShare i {
  border-radius: 33px;
  width: 61px;
  height: 61px;
}

.modalShare i:before {
  position: relative;
  top: 13px;
}

.modalShare .smd {
  width: 200px;
  font-size: small;
  text-align: center;
}

.modalShare input {
  border: none;
  background-color: #e6e2e2;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.modalShare input.focus,
input:focus {
  outline: 0;
  box-shadow: none !important;
}

.modalShare button {
  border: none;
  background-color: #e6e2e2;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
}

.modalShare button.focus,
button:focus {
  outline: 0;
  box-shadow: none !important;
}

.modalShareFooter {
  padding: 10px 0px;
  border-top: 1px solid black;
  display: block;
}

.modalShare a {
  color: black;
}

/* end of detail job */
@media only screen and (max-width: 329px) {
  .jobGrid {
    display: grid;
    grid-template-columns: 1fr;
    padding: 6px;
  }

  .filter {
    display: none;
  }

  .firstContainerWrap {
    padding: 9px 6px 6px 6px;
    margin: auto;
  }

  .sectionSearch {
    flex-direction: column;
    padding: 6px;
  }

  .sectionSearch__profession,
  .sectionSearch__location {
    width: 100%;
  }

  .sectionSearch > button {
    width: 100%;
  }

  .secondContainer {
    width: 100%;
    flex-wrap: wrap;
    padding: 0px 0px;
  }

  .job {
    padding: 20px 15px;
    width: 100%;
    display: grid;
    justify-content: center;
  }

  .infiniteScroll {
    padding: 25px 14px;
    display: flex;
    justify-content: start;
    gap: 40px;
    flex-direction: column;
  }

  .filterResponsiveBtnWrap {
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  .filterResponsiveBtn {
    display: block;
    background-color: #c0bfbf;
    border: none;
    color: rgb(0, 0, 0);
    width: 100%;
    height: 37px;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
  }

  .filterResponsiveInisdeBtnWrap {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  .filterResponsiveInsideBtn {
    display: block;
    background-color: #c0bfbf;
    border: none;
    color: rgb(0, 0, 0);
    width: 100%;
    height: 44px;
    border-radius: 30px;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
  }

  .filterRes {
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: #c0bfbf;
    top: 0;
    left: 0;
    z-index: 111;
  }

  .filterWrap {
    width: 100%;
    height: 100vh;
    border-radius: 0px;
  }

  .close {
    display: flex;
    justify-content: end;
    font-size: 40px;
    padding: 10px;
  }

  .filterShow {
    display: block;
  }

  .filterContent {
    width: 100%;
  }

  .card {
    width: 100%;
    background-color: #fffefe;
    box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 25%);
    border-radius: 8px;
    display: block;
    padding: 10px;
  }

  .image {
    padding: 0px;
  }

  .jobTitle {
    width: 100%;
  }

  .lastUpdate {
    justify-content: start;
  }

  /* detail job */
  .containerDetailJob {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .detailJobContent {
    width: 100%;
  }

  .jobContent {
    flex-direction: column;
    width: 100%;
    padding: 15px;
  }

  .jobContentTitle {
    font-size: 20px;
  }

  .status {
    width: 100%;
    padding: 0 10px;
  }

  .logoCompanyJobContent {
    width: 100px;
  }

  .aboutCompany {
    width: 100%;
    padding: 15px;
  }

  .aboutCompanyWrap {
    width: 100%;
  }

  .requirementWrapper {
    padding: 15px;
  }
}

@media only screen and (min-width: 330px) and (max-width: 767px) {
  .jobGrid {
    display: grid;
    gap: 24px 32px;
    grid-template-columns: 1fr;
    padding: 10px;
  }

  .filter {
    display: none;
  }

  .firstContainerWrap {
    padding: 20px 5px;
    margin: auto;
  }

  .sectionSearch {
    flex-direction: column;
    padding: 10px;
  }

  .sectionSearch__profession,
  .sectionSearch__location {
    width: 100%;
  }

  .sectionSearch > button {
    width: 100%;
  }

  .secondContainer {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px 0px;
  }

  .job {
    padding: 20px 15px;
    width: 100%;
    display: grid;
    justify-content: center;
  }

  .infiniteScroll {
    padding: 25px 15px;
    flex-direction: column;
    display: flex;
    justify-content: start;
    gap: 40px;
  }

  .filterResponsiveBtnWrap {
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  .filterResponsiveBtn {
    display: block;
    background-color: #c0bfbf;
    border: none;
    color: rgb(0, 0, 0);
    width: 100%;
    height: 37px;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
  }

  .filterResponsiveInisdeBtnWrap {
    display: flex;
    justify-content: center;
    padding: 0 0 25px 16px;
  }

  .filterResponsiveInsideBtn {
    display: block;
    background-color: #c0bfbf;
    border: none;
    color: rgb(0, 0, 0);
    width: 100%;
    height: 44px;
    border-radius: 30px;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
  }

  .filterRes {
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: #c0bfbf;
    top: 0;
    left: 0;
    z-index: 111;
  }

  .filterWrap {
    width: 100%;
    height: 100vh;
    border-radius: 0px;
  }

  .close {
    display: flex;
    justify-content: end;
    font-size: 40px;
    padding: 10px;
  }

  .filterShow {
    display: block;
  }

  .card {
    width: 100%;
  }

  .image {
    padding: 10px;
  }

  .jobTitle {
    width: 100%;
  }

  .lastUpdate {
    justify-content: start;
  }

  /* detail job */
  .containerDetailJob {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .detailJobContent {
    width: 100%;
  }

  .jobContent {
    flex-direction: column;
    width: 100%;
    padding: 15px;
  }

  .jobContentTitle {
    font-size: 20px;
  }

  .status {
    width: 100%;
    padding: 0 10px;
  }

  .logoCompanyJobContent {
    width: 100px;
  }

  .aboutCompany {
    width: 100%;
    padding: 15px;
  }

  .aboutCompanyWrap {
    width: 100%;
  }

  .requirementWrapper {
    padding: 15px;
  }

  /* end of detail job */
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .filter {
    display: none;
  }

  .filterRes {
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: #c0bfbf;
    top: 0;
    left: 0;
    z-index: 111;
  }

  .filterWrap {
    width: 100%;
    height: 100vh;
    border-radius: 0px;
  }

  .filterContent {
    width: 100%;
  }

  .firstContainer {
    width: 100%;
  }

  .firstContainerWrap {
    width: 100%;
    padding: 10px;
  }

  .sectionSearch {
    padding: 10px;
  }

  .secondContainer {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px 0px;
  }

  .job {
    padding: 20px 15px;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
  }

  .infiniteScroll {
    padding: 20px 15px;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
  }

  .card {
    width: 100%;
  }

  .filterResponsiveBtnWrap {
    display: flex;
    justify-content: center;
    padding: 15px 10px 0 10px;
  }

  .filterResponsiveBtn {
    display: block;
    background-color: #c0bfbf;
    border: none;
    color: rgb(0, 0, 0);
    width: 100%;
    height: 37px;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
  }

  .filterResponsiveInisdeBtnWrap {
    display: flex;
    justify-content: center;
    padding: 20px 10px;
  }

  .filterResponsiveInsideBtn {
    display: block;
    background-color: #c0bfbf;
    border: none;
    color: rgb(0, 0, 0);
    width: 100%;
    height: 44px;
    border-radius: 30px;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
  }

  .close {
    display: flex;
    justify-content: end;
    font-size: 40px;
    padding: 10px;
  }

  .filterShow {
    display: block;
  }

  .jobTitle {
    max-width: 210px;
  }

  .lastUpdate {
    justify-content: start;
  }

  /* detail job */
  .containerDetailJob {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .detailJobContent {
    width: 100%;
  }

  .jobContent {
    flex-direction: column;
    width: 100%;
    padding: 20px;
  }

  .status {
    width: 100%;
    padding: 0 20px;
  }

  .logoCompanyJobContent {
    width: 100px;
  }

  .aboutCompany {
    width: 100%;
    padding: 20px;
  }

  .aboutCompanyWrap {
    width: 100%;
  }

  .requirementWrapper {
    padding: 20px;
  }

  /* end of detail job */
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .secondContainer {
    gap: 1em;
    padding: 0px 10px;
  }

  .infiniteScroll {
    padding: 25px 0px;
    display: flex;
    justify-content: start;
    gap: 14px;
    flex-wrap: wrap;
  }
}

@media (min-width: 1280px) and (max-width: 1280px) {
  .secondContainer {
    gap: 1em;
    padding: 0px 50px;
  }

  .infiniteScroll {
    padding: 25px 0px;
    display: flex;
    justify-content: start;
    gap: 14px;
    flex-wrap: wrap;
  }
}
